.login-form-logo {
  display: block;
  width: 10.5rem;
  margin: 0.25rem auto 1.5rem;
}

@media only screen and (min-width: 600px) {
  .login-form-logo {
    width: 11.5rem;
  }
}
