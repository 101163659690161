.label {
  @include flex-space-between;
  margin-bottom: 0.375rem;

  .label-text {
    font: 500 0.875rem/1.25rem $font;
    color: $grey-dark;
  }

  .label-optional-text {
    font: 400 0.875rem/1.25rem $font;
    color: $grey-light;
  }

  .label-link {
    @include button-preset;
    text-decoration: underline;
    color: $grey-dark;
    font: 400 0.875rem/1.25rem $font;
    letter-spacing: 0.4px;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }

    &:focus {
      text-decoration: none;
    }
  }
}
