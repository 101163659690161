@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background-color: $grey-lightest;
}

#root {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:focus:focus-visible {
    outline: $orange-medium auto 1px;
  }
}

*::-moz-focus-inner {
  border: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

img,
svg {
  display: block;
  max-width: 100%;
}

select::-ms-expand {
  display: none;
}
