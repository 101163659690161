.input-password {
  position: relative;

  .input {
    padding-right: 3.25rem;
  }

  .icon-button {
    position: absolute;
    right: 0.5rem;
    top: 0.25rem;

    .icon-button-icon {
      color: $grey-light;
    }
  }
}
