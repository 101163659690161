@font-face {
  font-family: "icomoon";
  src: url("../../assets/icons/icomoon.eot?hvm9l0");
  src: url("../../assets/icons/icomoon.eot?hvm9l0#iefix")
      format("embedded-opentype"),
    url("../../assets/icons/icomoon.ttf?hvm9l0") format("truetype"),
    url("../../assets/icons/icomoon.woff?hvm9l0") format("woff"),
    url("../../assets/icons/icomoon.svg?hvm9l0#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    display: block;
  }
}

.icon-alert-circle-fill:before {
  content: "\e900";
}
.icon-alert-circle-outline:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e902";
  transform: rotate(270deg);
}
.icon-arrow-left:before {
  content: "\e902";
  transform: rotate(90deg);
}
.icon-calendar:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e904";
}
.icon-chevron-right:before {
  content: "\e904";
  transform: rotate(270deg);
}
.icon-chevron-left:before {
  content: "\e904";
  transform: rotate(90deg);
}
.icon-clipboard:before {
  content: "\e905";
}
.icon-clock:before {
  content: "\e91b";
}
.icon-cross-circle-fill:before {
  content: "\e906";
}
.icon-cross:before {
  content: "\e907";
}
.icon-download:before {
  content: "\e918";
}
.icon-email:before {
  content: "\e908";
}
.icon-external-link:before {
  content: "\e909";
}
.icon-eye-off:before {
  content: "\e90a";
}
.icon-eye:before {
  content: "\e90b";
}
.icon-fast:before {
  content: "\e919";
}
.icon-file:before {
  content: "\e91c";
}
.icon-filter:before {
  content: "\e90c";
}
.icon-lock:before {
  content: "\e917";
}
.icon-lock-outline:before {
  content: "\e91a";
}
.icon-menu:before {
  content: "\e91e";
}
.icon-more:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-record:before {
  content: "\e91f";
}
.icon-sort:before {
  content: "\e90f";
}
.icon-survey:before {
  content: "\e912";
}
.icon-thermometer:before {
  content: "\e910";
}
.icon-tick-circle-fill:before {
  content: "\e911";
}
.icon-tick:before {
  content: "\e91d";
}
.icon-trash:before {
  content: "\e913";
}
.icon-upload:before {
  content: "\e914";
}
.icon-vaccine:before {
  content: "\e915";
}
.icon-wedge:before {
  content: "\e916";
}
