$grey-darkest: #121729;
$grey-darker: #383f57;
$grey-dark: #62677a;
$grey-medium: #9fa2af;
$grey-light: #d3d5e0;
$grey-lighter: #ededf0;
$grey-lightest: #f2f2f5;

$orange-dark: #ff9900;
$orange-medium: #ffae33;
$orange-light: #fff5e6;

$red-dark: #d1435b;
$red-medium: #f54562;
$red-light: #fff0f2;

$green-darkest: #007a52;
$green-dark: #3c8168;
$green-medium: #36b37e;
$green-light: #92e6bb;
$green-lightest: #e3fcef;

$yellow-dark: #705e28;
$yellow-light: #fff7e0;
