.button-link {
  @include button-preset;
  text-decoration: underline;
  font-size: 0.875rem;
  letter-spacing: 0.4px;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .button-link {
    font-size: 1rem;
  }
}
