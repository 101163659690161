@use "sass:math";

// Padding

.p-1 {
  padding: #{$unit * 1}rem;
}

.p-2 {
  padding: #{$unit * 2}rem;
}

.p-3 {
  padding: #{$unit * 3}rem;
}

.p-4 {
  padding: #{$unit * 4}rem;
}

.p-5 {
  padding: #{$unit * 5}rem;
}

.p-6 {
  padding: #{$unit * 6}rem;
}

.p-7 {
  padding: #{$unit * 7}rem;
}

.p-8 {
  padding: #{$unit * 8}rem;
}

// Padding Top

.pt-1 {
  padding-top: #{$unit * 1}rem;
}

.pt-2 {
  padding-top: #{$unit * 2}rem;
}

.pt-3 {
  padding-top: #{$unit * 3}rem;
}

.pt-4 {
  padding-top: #{$unit * 4}rem;
}

.pt-5 {
  padding-top: #{$unit * 5}rem;
}

.pt-6 {
  padding-top: #{$unit * 6}rem;
}

.pt-7 {
  padding-top: #{$unit * 7}rem;
}

.pt-8 {
  padding-top: #{$unit * 8}rem;
}

// Padding Bottom

.pb-1 {
  padding-bottom: #{$unit * 1}rem;
}

.pb-2 {
  padding-bottom: #{$unit * 2}rem;
}

.pb-3 {
  padding-bottom: #{$unit * 3}rem;
}

.pb-4 {
  padding-bottom: #{$unit * 4}rem;
}

.pb-5 {
  padding-bottom: #{$unit * 5}rem;
}

.pb-6 {
  padding-bottom: #{$unit * 6}rem;
}

.pb-7 {
  padding-bottom: #{$unit * 7}rem;
}

.pb-8 {
  padding-bottom: #{$unit * 8}rem;
}
