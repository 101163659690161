.input-select {
  &.input-select-error {
    .react-select__control {
      border-color: $red-medium;
    }
  }

  .react-select__control {
    border-color: $grey-lightest;
    transition: none;
    font-size: 16px !important;
    font-weight: 400;

    @media (hover: hover) {
      &:hover {
        border-color: $grey-lightest;
      }
    }

    &.react-select__control--is-focused {
      border-color: none;
      font-size: 16px !important;
      letter-spacing: 0.4px;
      outline: $orange-medium auto 1px !important;
      box-shadow: none;
    }

    .react-select__value-container {
      padding: calculate-rem(6px) 0.875rem;

      .react-select__input {
        input {
          font-family: $font;
          letter-spacing: 0.4px;
          width: 100% !important;
        }
      }

      .react-select__single-value {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $grey-darkest;
      }

      .react-select__placeholder {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $grey-medium;
      }
    }

    .react-select__indicators {
      .react-select__indicator {
        padding-right: 0.875rem;

        svg {
          fill: $grey-light;
        }
      }

      .react-select__indicator-separator {
        display: none;
      }
    }
  }

  .react-select__menu {
    border-color: $orange-medium;

    .react-select__menu-list {
      .react-select__option {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.75rem;

        @media (hover: hover) {
          &:hover {
            background-color: $grey-lightest;
          }
        }

        &:focus:focus-visible {
          outline: $orange-medium auto 1px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .input-select {
    line-height: 1.625rem;
  }
}
