.icon-button {
  @include button-preset;
  display: block;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;

  &:focus {
    background-color: $grey-lightest;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $grey-lightest;

      .icon-button-icon {
        color: $grey-dark;
      }
    }
  }

  .icon-button-icon {
    display: block;
    font-size: 1.5rem;
    color: $grey-medium;

    &:before {
      display: block;
    }
  }
}
