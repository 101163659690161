.input {
  appearance: none;
  display: block;
  width: 100%;
  border: 1px solid $grey-lighter;
  border-radius: 0.25rem;
  font: 400 1rem/1.5rem $font;
  letter-spacing: 0.4px;
  padding: calculate-rem(11px) 1rem;
  color: $grey-darkest;

  &:placeholder-shown,
  &::placeholder {
    color: $grey-medium;
  }

  &:focus {
    outline: $orange-medium auto 1px;
  }

  &.input-error {
    border-color: $red-medium;
  }
}

.input-error-text {
  font: 400 0.875rem/1.25rem $font;
  min-height: 1.25rem;
  color: $red-dark;
  display: block;
  margin-bottom: 0.25rem;
}
