.list-page-empty {
  text-align: center;
  background-color: white;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.25rem;

  .list-page-empty-description {
    margin: 0 0 0.75rem 0;
  }

  .button {
    margin: 0 auto;
  }
}

.list-page-table {
  display: none;

  @media only screen and (min-width: 600px) {
    display: table;
  }

  background-color: white;
  width: 100%;
  border-radius: 0.5rem;
  text-align: left;
  font: 400 0.875rem/1.25rem $font;
  color: $grey-dark;
  border: 1px solid $grey-lighter;

  .list-page-table-header {
    font-weight: 500;

    .list-page-table-header-cell {
      padding: 0.5rem 0.75rem 0.375rem;
      border-bottom: 0.125rem solid transparent;

      &.list-page-table-header-cell-sorted {
        border-bottom: 0.125rem solid $orange-medium;

        .list-page-table-header-cell-text {
          color: $grey-darkest;
        }
      }

      .list-page-table-header-cell-layout {
        @include flex-justify-start;

        .list-page-table-header-cell-text {
          display: block;
          margin: 0.125rem 0;
          max-width: calc(100% - 1.625rem);
          font-weight: 500;

          @media only screen and (min-width: 1378px) {
            max-width: none;
          }
        }

        .list-page-table-header-cell-icon {
          font-size: 1.5rem;
          color: $grey-medium;
        }
      }

      @media (hover: hover) {
        &:hover {
          background-color: $grey-lightest;
        }
      }

      &:first-of-type {
        padding-left: 1rem;
      }

      &:last-of-type {
        padding-right: 1rem;
      }
    }
  }

  .list-page-table-body {
    .list-page-table-body-row {
      .list-page-table-body-cell {
        border-top: 1px solid $grey-lighter;
        padding: 0.5rem;
        vertical-align: top;
        word-break: break-all;

        &:first-of-type {
          padding-left: 1rem;
        }

        &:last-of-type {
          padding-right: 1rem;
        }

        &.list-page-table-body-cell-desktop {
          display: none;

          @media only screen and (min-width: 1200px) {
            display: table-cell;
          }
        }

        &.list-page-table-body-cell-button {
          padding: 0.25rem;
        }

        .list-page-table-body-cell-final-column {
          display: grid;
          grid-template-columns: 1fr 1.5rem;

          .list-page-table-body-cell-final-column-icon {
            font-size: 1.5rem;
            color: $grey-light;
          }
        }

        .list-page-table-body-cell-text {
          display: block;
          line-height: 1.25rem;
          margin: 0.375rem 0;
        }

        .list-page-table-body-cell-bold {
          font-weight: 600;
          color: $grey-darkest;
        }

        .list-page-table-body-cell-reading {
          display: grid;
          grid-template-columns: 1.5rem 4.5rem 1.5rem;
          align-items: center;
          gap: 0.5rem;

          .list-page-table-body-cell-reading-icon {
            font-size: 1.5rem;

            &.icon-tick-circle-fill {
              color: $green-medium;
            }

            &.icon-alert-circle-fill {
              color: $red-dark;
            }

            &.icon-clock {
              color: $grey-medium;
            }
          }

          .list-page-table-body-cell-reading-chevron {
            font-size: 1.5rem;
            color: $grey-light;
          }
        }
      }
    }
  }
}
