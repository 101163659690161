@import "./Breadcrumbs/breadcrumbs";

.layout {
  padding-top: 1rem;
  padding-bottom: 1.25rem;
  position: relative;
}

.nav {
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 0.3755rem 1rem 0.375rem 1.25rem;
  display: grid;
  grid-template-columns: 1fr 5rem;
  align-items: center;
  z-index: 997;

  .nav-logo {
    svg {
      height: 2rem;
    }
  }

  .nav-log-out-button {
    @include button-preset;
    grid-row: 1;
    padding: 0.5rem 0.75rem;
    font: 600 0.875rem/1.25rem $font;
    letter-spacing: 0.4px;
    text-align: center;
    color: $grey-medium;
    background-color: transparent;
    will-change: background-color, color;
    border-radius: 0.5rem;

    @media (hover: hover) {
      &:hover {
        color: $grey-dark;
        background-color: $grey-lightest;
      }
    }

    &:focus {
      color: $grey-dark;
      background-color: $grey-lightest;
    }
  }
}

@media only screen and (min-width: 600px) {
  .nav {
    padding: 0.5rem 1.5rem;
    @include flex-space-between;
    align-items: center;

    .nav-log-out {
      width: 10rem;
      @include flex-justify-end;

      .nav-log-out-button {
        grid-row: unset;
      }
    }

    .nav-logo {
      width: 10rem;

      svg {
        height: 2.5rem;
      }
    }
  }

  .layout {
    padding-top: 1.5rem;
  }
}
