.tag {
  font: 500 0.875rem/1.25rem $font;
  padding: calculate-rem(5px) 0.625rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  display: block;
  max-width: 100%;

  @supports (width: max-content) {
    width: max-content;
  }

  &.tag-icon {
    @include flex-justify-start;
    padding: 0.25rem 0.625rem 0.25rem 0.375rem;

    &.tag-success {
      .tag-icon-icon {
        color: $green-medium;
      }
    }

    .tag-icon-icon {
      font-size: 1.5rem;
      width: 1.5rem;
    }

    .tag-icon-text {
      display: block;
      width: calc(100% - 1.875rem);
      margin: 0.125rem 0 0.125rem 0.375rem;
    }
  }

  &.tag-background {
    &.tag-warning {
      background-color: $yellow-light;
    }

    &.tag-error {
      background-color: $red-light;
    }

    &.tag-info {
      background-color: $grey-lightest;
    }

    &.tag-success {
      background-color: $green-lightest;
    }

    &.tag-white {
      padding: 0.25rem 0.5rem;
      background-color: white;
    }
  }

  &.tag-no-background {
    margin-right: 0;

    .tag-icon-text {
      color: $grey-dark;
    }
  }

  &.tag-warning {
    color: $yellow-dark;
  }

  &.tag-error {
    color: $red-dark;
  }

  &.tag-info {
    color: $grey-dark;
  }

  &.tag-success {
    color: $green-darkest;
  }

  &.tag-white {
    color: $grey-dark;
  }
}
