.wrapper-width {
  display: block;
  margin: 0 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media only screen and (min-width: 700px) {
  .wrapper-width {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 1100px) {
  .wrapper-width {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media only screen and (min-width: 1344px) {
  .wrapper-width {
    max-width: 78rem;
    padding-left: 0;
    padding-right: 0;
  }
}
