.button-list {
  @include flex-justify-start;
  width: 100%;

  &.button-list-vertical {
    display: block;
    margin-top: 1rem;

    .button {
      @supports (width: max-content) {
        width: max-content;
      }
    }
  }

  &.button-list-vertical-merged {
    display: block;
    // only set up for button style="secondary"

    .button {
      border-radius: 0;
      margin: 0;
      border-bottom: none;

      &:first-of-type {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-of-type {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-bottom: 1px solid $grey-lightest;
      }
    }
  }

  &.button-list-centered {
    justify-content: center;
  }

  .button {
    margin-right: 0.75rem;
    margin-bottom: 0.5rem;

    &:only-child {
      margin: 0;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .button-list {
    &.button-list-vertical {
      margin-top: 1.5rem;
    }
  }
}
