.breadcrumbs {
  @include flex-justify-start;
  align-items: center;
  grid-row: 2;
  grid-column: 1/3;
  margin-top: 0.25rem;

  .breadcrumb {
    @include flex-justify-start;
    align-items: center;
    margin-left: 0.25rem;

    &:first-of-type {
      margin-left: 0;

      .breakcrumb-link {
        margin-left: 0;
      }
    }

    .breadcrumb-icon {
      display: block;
      font-size: 1.25rem;
      color: $grey-light;
    }

    .breadcrumb-link {
      margin-left: 0.25rem;
      font: 500 0.875rem/1.25rem $font;
      color: $grey-medium;
      text-decoration: none;
      will-change: background-color;
      background-color: white;
      padding: 0.5rem 0.75rem;
      border-radius: 0.5rem;

      @media (hover: hover) {
        &:hover {
          background-color: $grey-lightest;
          color: $grey-dark;
        }
      }

      &:focus {
        background-color: $grey-lightest;
        color: $grey-dark;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .breadcrumbs {
    grid-row: unset;

    .breadcrumb {
      .breadcrumb-link {
        font-size: 1rem;
      }
    }
  }
}
