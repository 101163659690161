.warning-modal {
  text-align: center;
  padding: 1.5rem;

  .warning-modal-header {
    .icon-alert-circle-outline {
      font-size: 1.75rem;
      color: $red-dark;
    }

    .warning-modal-header-title {
      display: block;
      margin-top: 0.25rem;
    }
  }

  .warning-modal-body {
    margin-top: 0.25rem;
    color: $grey-dark;
    display: block;
    font: 400 0.875rem/1.625rem $font;

    .warning-modal-body-list {
      .warning-modal-body-list-item {
        margin-top: 0.5rem;
      }
    }
  }

  .warning-modal-footer {
    margin-top: 1rem;

    .button {
      margin: 0 auto 0.5rem;
    }
  }
}

@media only screen and (min-width: 720px) {
  .warning-modal {
    max-width: 27.5rem;
    padding: 2rem;

    .warning-modal-header {
      .warning-modal-header-title {
        margin-top: 0.5rem;
      }
    }

    .warning-modal-body {
      margin-top: 0.5rem;
    }

    .warning-modal-footer {
      margin-top: 1.5rem;
    }
  }
}
