.status-reporting-group-card {
  margin-bottom: 0.75rem;
  background-color: white;
  width: 100%;
  border-radius: 0.5rem;
  text-align: left;
  font: 400 0.875rem/1.25rem $font;
  color: $grey-dark;
  border: 1px solid $grey-lighter;
  vertical-align: top;

  &:last-of-type {
    margin-bottom: 0;
  }

  .status-reporting-group-card-row {
    &:last-of-type {
      .status-reporting-group-card-header,
      .status-reporting-group-card-body {
        border-bottom: none;
      }
    }

    .status-reporting-group-card-row-text {
      display: block;
      margin: 0.375rem 0;
    }

    .status-reporting-group-card-header {
      padding: 0.5rem;
      width: 50%;
      font-weight: 500;
      border-bottom: 1px solid $grey-lighter;
    }

    .status-reporting-group-card-body {
      width: 50%;
      word-break: break-all;
      padding: 0.5rem;
      border-bottom: 1px solid $grey-lighter;
    }
  }
}

@media only screen and (min-width: 600px) {
  .status-reporting-group-cards {
    display: none;
  }
}
