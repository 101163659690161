.loader {
  @include animation-preset;
  display: block;
  content: "";
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
  animation: loaderSpin 600ms forwards infinite;

  &.loader-orange {
    border: $orange-medium calculate-rem(3px) solid;
    border-top: transparent calculate-rem(3px) solid;
  }

  &.loader-grey {
    border: $grey-medium calculate-rem(3px) solid;
    border-top: transparent calculate-rem(3px) solid;
  }
}
