@import "./StatusReportingGroupCards/status-reporting-group-cards";

.status-reporting-group {
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.25rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .status-reporting-group-header {
    margin-bottom: 0.5rem;
  }

  .status-reporting-group-body {
    margin-top: 0.5rem;
  }
}

@media only screen and (min-width: 900px) {
  .status-reporting-group {
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    .status-reporting-group-header {
      margin-bottom: 0.75rem;
    }
  }
}
