.link {
  text-decoration: underline;
  color: $grey-dark;

  &:focus {
    text-decoration: none;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
}
