.empty-state {
  border: 1px solid $grey-lightest;
  text-align: center;
  @include flex-justify-center;
  border-radius: 0.5rem;
  padding: 0.75rem;

  * {
    width: 100%;
    @include flex-justify-center;
  }
}

@media only screen and (min-width: 900px) {
  .empty-state {
    padding: 1.25rem;
  }
}
