.flex-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.flex-justify-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
