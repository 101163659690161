@use "sass:math";

.border-radius-1 {
  border-radius: #{$unit * 1}rem;
}

.border-radius-2 {
  border-radius: #{$unit * 2}rem;
}

.border-radius-3 {
  border-radius: #{$unit * 3}rem;
}

.border-radius-4 {
  border-radius: #{$unit * 4}rem;
}
