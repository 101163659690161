.form-confirmation {
  text-align: center;
  padding: 0 1rem;

  .form-confirmation-icon {
    font-size: 1.5rem;
    color: $green-medium;
  }

  .form-confirmation-heading {
    margin: 0.25rem 0 0.75rem 0;
  }

  .form-legend {
    max-width: 25rem;
    margin: 0 auto;
  }

  .heading-confirmation-footer {
    margin-top: 1rem;

    .button {
      display: block;
      margin: 0 auto 0.5rem;

      @supports (width: max-content) {
        width: max-content;
      }
    }
  }
}
