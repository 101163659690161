@import "./StatusReportingGroup/status-reporting-group";

.dashboard-header {
  padding-bottom: 0.75rem;
}

@media only screen and (min-width: 900px) {
  .dashboard-header {
    @include flex-space-between;
    align-items: center;
    padding-bottom: 1rem;
  }
}
